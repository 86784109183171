import { _jsonParseIfPossible, StringMap } from '@naturalcycles/js-lib'
import { getCookie } from './storage'

export const getUtmQueryParams = (): StringMap => {
  const qp = new URLSearchParams(window.location.search)
  const utms = {
    // utms from a Cookie
    ..._jsonParseIfPossible(getCookie('utms')),
    // utms from QueryString
    ...Object.fromEntries(
      [...qp.entries()].filter(([key, value]) => {
        return key.startsWith('utm') && value !== undefined && value !== ''
      }),
    ),
  }
  return utms
}
