import { _stringEnumKey } from '@naturalcycles/js-lib'
import BurgerIcon from '@src/components/burger-icon/BurgerIcon.component'
import { ClientOnly } from '@src/components/client-only/ClientOnly.component'
import DesktopMenu from '@src/components/desktop-menu/DesktopMenu.component'
import { HeaderThemes } from '@src/components/header/Header.component'
import Logo from '@src/components/logo/Logo.component'
import RegionIndicator from '@src/components/region-indicator/RegionIndicator.component'
import RegionPicker from '@src/components/region-picker/RegionPicker.component'
import { useAppDispatch } from '@src/hooks/useAppDispatch'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { Breakpoints, useBreakpoint } from '@src/hooks/useBreakpoint'
import { RegionSlug, ShippingLocations, shippingRegionPages } from '@src/shop/cnst/shopify.cnst'
import ShopifyCartIcon from '@src/shop/components/cart-icon/CartIcon.component'
import {
  selectExpectedLocation,
  selectShopifyShippingLocation,
  setExpectedLocation,
  setShippingLocation,
} from '@src/shop/store/shopify.slice'
import React, { useEffect, useMemo } from 'react'
import styles from './HeaderShop.module.scss'

interface HeaderShopProps {}

const HeaderShop = ({}: HeaderShopProps): React.ReactElement => {
  const isMobile = useBreakpoint().width < Breakpoints.bpLarge
  const shippingLocation = useAppSelector(selectShopifyShippingLocation)

  const urlFragmentToShippingLocation = (url: string) => {
    const parts = url.split('/')
    const shopIndex = parts.indexOf('shop')
    const locationIndex = shopIndex + 1
    const urlLocationPart = parts[locationIndex]
    return shippingRegionPages.find(page => page.slug === urlLocationPart)?.shopifyTag || null
  }

  const dispatch = useAppDispatch()

  const getHomeUrl = (shippingLocation?: ShippingLocations) => {
    if (!shippingLocation) return '/shop/row'
    const regionKeyOrBlocked = _stringEnumKey(ShippingLocations, shippingLocation)
    const slugSuffix = regionKeyOrBlocked === 'Blocked' ? 'row' : RegionSlug[regionKeyOrBlocked]
    return `/shop/${slugSuffix}`
  }

  const [shopHomeUrl, setShopHomeUrl] = React.useState(getHomeUrl(shippingLocation))

  const urlLocation =
    typeof window !== 'undefined' ? urlFragmentToShippingLocation(window.location.pathname) : null

  const selectRegion = (shippingRegion?: ShippingLocations) => {
    dispatch(setShippingLocation(shippingRegion))
  }
  const saveRegion = (shippingRegion?: ShippingLocations) => {
    if (typeof window !== 'undefined' && shippingRegion) {
      window.sessionStorage.setItem('Region:', shippingRegion)
    }
  }
  const savedRegion = useMemo(
    () =>
      typeof window !== 'undefined'
        ? (sessionStorage.getItem('Region:') as ShippingLocations)
        : null,
    [],
  )

  const savedExpectedLocation = useMemo(
    () =>
      typeof window !== 'undefined'
        ? (sessionStorage.getItem('ExpectedLocation:') as ShippingLocations)
        : null,
    [],
  )
  const saveExpectedLocation = (shippingRegion: ShippingLocations | undefined) => {
    if (typeof window !== 'undefined' && shippingRegion) {
      window.sessionStorage.setItem('ExpectedLocation:', shippingRegion)
    }
  }

  const useRegion = urlLocation || savedRegion || shippingLocation

  const expectedLocation = useAppSelector(selectExpectedLocation)

  const [showRegionIndicator, setShowRegionIndicator] = React.useState(false)

  useEffect(() => {
    saveExpectedLocation(expectedLocation)
  }, [expectedLocation])

  useEffect(() => {
    if (!expectedLocation) {
      dispatch(setExpectedLocation(savedExpectedLocation || savedRegion || shippingLocation))
    }

    if (useRegion) {
      setShowRegionIndicator(useRegion !== expectedLocation)
    }

    saveRegion(useRegion)
    setShopHomeUrl(getHomeUrl(useRegion))
    selectRegion(useRegion)
  }, [shippingLocation, expectedLocation, useRegion])

  return (
    <div className={styles.HeaderShop}>
      <ClientOnly>{showRegionIndicator && <RegionIndicator />}</ClientOnly>
      <div className={styles.HeaderShop__layout}>
        <div className={styles.HeaderShop__burger}>
          <BurgerIcon color="#fff" />
        </div>

        <div className={styles.HeaderShop__logo}>
          <ClientOnly>
            <Logo href={shopHomeUrl} color="#fff" uid="header-shop-logo" />
          </ClientOnly>
        </div>

        <div className={styles.HeaderShop__menu}>
          <DesktopMenu theme={HeaderThemes.SupportPurple} />
        </div>

        {isMobile ? (
          <></>
        ) : (
          <div className={styles.HeaderShop__regionPicker}>
            <RegionPicker />
          </div>
        )}

        <div className={styles.HeaderShop__cart}>
          <ShopifyCartIcon />
        </div>
      </div>
    </div>
  )
}

export default HeaderShop
