import { BannerDiscountCode } from '@src/components/banner-discount-code/BannerDiscountCode.component'
import HeaderHcp from '@src/components/header-hcp/HeaderHcp.component'
import HeaderPress from '@src/components/header-press/HeaderPress.component'
import { HeaderStandard } from '@src/components/header-standard/HeaderStandard.component'
import MobileMenu from '@src/components/mobile-menu/MobileMenu.component'
import qaContext from '@src/context/qa.context'
import { getLayout } from '@src/helpers/layout'
import { redDotUnlockSequence } from '@src/helpers/redDot'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { PageTypes, usePageType } from '@src/hooks/usePageType'
import BannerShop from '@src/shop/components/banner-shop/BannerShop.component'
import HeaderShop from '@src/shop/components/header-shop/HeaderShop.component'
import { MobileMenuStatuses, selectMobileMenuStatus } from '@src/store/mobileMenu/mobileMenu.slice'
import classNames from 'classnames'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { ClientOnly } from '../client-only/ClientOnly.component'
import styles from './Header.module.scss'

export enum HeaderLayouts {
  StandardHome = 'StandardHome',
  Standard = 'Standard',
  Hcp = 'Hcp',
  Press = 'Press',
  Shop = 'Shop',
  ShopHome = 'ShopHome',
  ShopHomeLocationIndicator = 'ShopHomeLocationIndicator',
  StandardHomeDiscountBanner = 'StandardHomeDiscountBanner',
  StandardDiscountBanner = 'StandardDiscountBanner',
  ShopLocationIndicator = 'ShopLocationIndicator',
}

export enum HeaderPositions {
  Absolute = 'Absolute',
  Fixed = 'Fixed',
}

export enum HeaderThemes {
  TransparentSupportPurple = 'TransparentSupportPurple',
  SupportPurple = 'SupportPurple',
  Blue = 'Blue',
  White = 'White',
}

const standard = [
  HeaderLayouts.StandardHome,
  HeaderLayouts.Standard,
  HeaderLayouts.StandardHomeDiscountBanner,
  HeaderLayouts.StandardDiscountBanner,
]
const shop = [
  HeaderLayouts.Shop,
  HeaderLayouts.ShopLocationIndicator,
  HeaderLayouts.ShopHome,
  HeaderLayouts.ShopHomeLocationIndicator,
]

export interface HeaderProps {
  // Specifically added for DynamicTemplate, which has banner that are managed through Dato
  canHaveDiscountBanner?: boolean
}

export const Header = (props: HeaderProps): React.ReactElement => {
  const { canHaveDiscountBanner = true } = props
  const mobileMenuStatus = useAppSelector(selectMobileMenuStatus)
  const discountCode = useAppSelector(state => state.discountCode.code)
  const pageType = usePageType()
  const position = HeaderPositions.Fixed
  const [code, setCode] = useState('')
  const { toggleRedDot } = useContext(qaContext)
  const sequenceState = useState(0)

  // Determine if a discount code is present and if they saved their region
  useEffect(() => {
    setCode(discountCode || sessionStorage.getItem('discountCode') || '')
  }, [discountCode])

  const layout = useMemo(() => {
    return getLayout(pageType, code)
  }, [pageType, code])

  // Flip this switch if you want the non-sticky header back for the homepage
  // if (pageType === PageTypes.StandardHome && mobileMenuStatus === MobileMenuStatuses.Closed) {
  //   position = HeaderPositions.Absolute
  // }

  const className = classNames(
    styles.Header,
    styles[`Header__layout${layout}`],
    styles[`Header__position${position}`],
    {
      [styles.Header__hideBanners]:
        mobileMenuStatus === MobileMenuStatuses.Open && layout !== HeaderLayouts.Hcp,
    },
  )

  if ([PageTypes.Campaign, PageTypes.WebView].includes(pageType)) {
    return <></>
  }

  return (
    <header
      uid="site-header"
      onTouchStart={e => redDotUnlockSequence(e, sequenceState, toggleRedDot)}
      className={className}
    >
      <Helmet>
        <body data-header-layout={layout} />
      </Helmet>

      {standard.includes(layout) && <HeaderStandard layout={layout} headerTheme="white" />}
      {layout === HeaderLayouts.Hcp && <HeaderHcp />}
      {layout === HeaderLayouts.Press && <HeaderPress />}
      {shop.includes(layout) && <HeaderShop />}

      <ClientOnly>
        {standard.includes(layout) && canHaveDiscountBanner && <BannerDiscountCode />}
      </ClientOnly>
      {[HeaderLayouts.ShopHome, HeaderLayouts.ShopHomeLocationIndicator].includes(layout) && (
        <BannerShop />
      )}

      <MobileMenu />
    </header>
  )
}
