import { Marquee } from '@src/components/marquee-animation/MarqueeAnimation.component'
import classNames from 'classnames'
import React from 'react'
import styles from './RotatingUsp.module.scss'

interface RotatingUspProps {
  backgroundColor?: 'default' | 'grey'
  uspsList: string[]
  theme: 'page' | 'footer'
  /** Whether the marquee should start bold or not */
  startBold?: boolean
}

export const RotatingUsp = ({
  backgroundColor,
  uspsList,
  theme,
  startBold = false,
}: RotatingUspProps) => {
  const sectionClass = classNames(styles.RotatingUsp__container, {
    [styles.RotatingUsp___grey]: backgroundColor === 'grey',
    [styles.RotatingUsp___pageComponent]: theme === 'page',
  })

  return (
    <div className={styles.RotatingUsp}>
      <Marquee autoFill={true}>
        <div className={sectionClass}>
          {uspsList.map((usp, index) => (
            <p
              className={classNames(styles.RotatingUsp__text, {
                [styles.RotatingUsp__text___regular]: startBold ? index % 2 === 1 : index % 2 === 0,
              })}
              key={usp}
            >
              {usp}
            </p>
          ))}
        </div>
      </Marquee>
    </div>
  )
}
