import { brandPurple } from '@src/cnst/colors.cnst'
import LanguageContext from '@src/context/language.context'
import { useLocalePrefix } from '@src/hooks/useLocalePrefix'
import React from 'react'

interface LogoProps {
  uid: string
  color?: string
  link?: boolean
  href?: string
}

const Logo = ({ color = brandPurple, link = true, href, uid }: LogoProps): React.ReactElement => {
  const { locale } = React.useContext(LanguageContext)
  const localizedHomeUrl = locale === 'en-US' ? useLocalePrefix('/') : useLocalePrefix('')

  const svg = (
    <svg uid={!link ? uid : ''} width="100%" viewBox={`0 0 237 32`} role="link">
      <title>Natural Cycles Logo</title>
      <path
        d="M158.631 7.71694C158.78 7.19552 159.063 6.97205 159.688 6.97205H163.487C164.009 6.97205 164.381 7.49347 164.202 8.04469L156.381 30.5551C156.247 30.9276 155.979 31.1361 155.577 31.1361H152.091C151.45 31.1361 151.092 30.5998 151.301 30.0486L153.878 23.8661H152.686C152.254 23.8661 151.971 23.6426 151.837 23.2255L146.623 8.19367C146.37 7.46368 146.772 6.95715 147.428 6.95715H151.122C151.644 6.95715 151.986 7.07634 152.165 7.65735L155.443 19.2627L158.631 7.71694Z"
        fill={color}
      />
      <path
        d="M144.969 7.98505C144.835 8.02975 141.781 9.05769 141.781 9.05769C141.409 9.17687 141.051 9.01299 140.873 8.70014C139.636 6.42079 137.655 4.94592 134.779 4.94592C130.787 4.94592 127.807 8.23831 127.807 12.2011C127.807 16.1639 130.846 19.4116 134.779 19.4116C137.774 19.4116 139.815 17.892 140.962 15.7617C141.26 15.1955 141.588 15.0615 142.198 15.2998L145.267 16.4767C145.461 16.5512 145.625 16.7002 145.699 16.9088C145.774 17.1024 145.774 17.3259 145.685 17.5047C143.658 21.7654 139.562 24.4023 134.779 24.4023C128.165 24.4023 122.802 18.9349 122.802 12.2011C122.802 5.46734 128.18 -0.00012207 134.779 -0.00012207C139.338 -0.00012207 143.405 2.54738 145.476 6.67405C145.774 7.29976 145.536 7.79138 144.969 7.98505Z"
        fill={color}
      />
      <path
        d="M86.4812 6.58472C85.0213 6.58472 83.2931 7.56797 82.2354 8.53632L81.7587 8.98325V7.82123C81.7587 7.13593 81.3415 6.97206 80.7903 6.97206H77.6469C77.1404 6.97206 76.8126 7.32961 76.8126 7.82123V23.2255C76.8126 23.7618 77.1404 24.0895 77.632 24.0895H80.9244C81.416 24.0895 81.7438 23.6873 81.7438 23.2553V16.5662C81.7438 13.4973 83.2931 11.7989 86.3472 11.7989C86.958 11.7989 87.2559 11.4712 87.2559 11.0093V7.40409C87.2708 6.85288 86.9431 6.58472 86.4812 6.58472Z"
        fill={color}
      />
      <path
        d="M30.4955 6.65918C25.9368 6.65918 22.2124 10.6369 22.2124 15.5382C22.2124 20.4247 25.9219 24.4173 30.4955 24.4173C32.2088 24.4173 33.8028 23.8511 35.0989 22.7636L35.5608 22.3763V23.3595C35.5608 23.7618 35.8885 24.0895 36.2907 24.0895H39.6278C40.1045 24.0895 40.4919 23.7022 40.4919 23.2254V7.88079C40.4919 7.34448 40.2237 6.97203 39.6129 6.97203H36.5142C35.8438 6.97203 35.5608 7.26999 35.5608 7.8659V8.71507C34.4881 7.67223 32.76 6.65918 30.4955 6.65918ZM31.5384 19.9628C29.0505 19.9628 27.3223 18.1453 27.3223 15.5382C27.3223 12.9758 29.0951 11.1136 31.5384 11.1136C34.0263 11.1136 35.9183 13.0205 35.9183 15.5382C35.9034 18.0112 33.9816 19.9628 31.5384 19.9628Z"
        fill={color}
      />
      <path
        d="M96.1797 6.65918C91.621 6.65918 87.8966 10.6369 87.8966 15.5382C87.8966 20.4247 91.6061 24.4173 96.1797 24.4173C97.893 24.4173 99.487 23.8511 100.783 22.7636L101.245 22.3763V23.3148C101.245 23.7469 101.647 24.1044 102.035 24.1044H105.431C105.848 24.1044 106.176 23.7618 106.176 23.3595V7.71692C106.176 7.31468 105.848 6.98693 105.446 6.98693H102.02C101.558 6.98693 101.245 7.28488 101.245 7.77651V8.74486C100.172 7.67222 98.4442 6.65918 96.1797 6.65918ZM97.2226 19.9628C94.7347 19.9628 93.0065 18.1453 93.0065 15.5382C93.0065 12.9758 94.7793 11.1136 97.2226 11.1136C99.7105 11.1136 101.603 13.0205 101.603 15.5382C101.588 18.0112 99.6658 19.9628 97.2226 19.9628Z"
        fill={color}
      />
      <path
        d="M69.2 22.2571V23.4191C69.2 23.7916 69.5128 24.1044 69.8853 24.1044H73.1181C73.6693 24.1044 74.0119 23.8363 74.0119 23.2255V7.80632C74.0119 7.2849 73.6544 6.97205 73.1032 6.97205H70.1832C69.5128 6.97205 69.2 7.2849 69.2 8.01489V15.7915C69.2 19.0243 67.5165 20.2161 65.5202 20.2161C63.1515 20.2161 61.9001 18.6965 61.9001 15.8064L61.8554 7.86591C61.8554 7.34449 61.5872 6.98694 60.9466 6.98694H57.6989C57.0434 6.98694 56.7604 7.2998 56.7604 7.9106V15.5382C56.7604 20.961 59.6952 24.3428 64.6711 24.3428C66.9057 24.3428 68.47 23.3893 69.1553 22.2571C69.1702 22.2124 69.2 22.2273 69.2 22.2571Z"
        fill={color}
      />
      <path
        d="M4.91625 9.83268C4.91625 9.80288 4.94604 9.78798 4.97584 9.81778L14.4061 23.7024C14.5849 23.9557 14.8381 24.1047 15.1808 24.1047H19.5756C20.1566 24.1047 20.4546 23.7471 20.4546 23.2406V1.34098C20.4546 0.864254 20.1417 0.521606 19.6203 0.521606H16.3726C15.881 0.521606 15.5383 0.87915 15.5383 1.46016V16.8048C15.5383 16.8346 15.4936 16.8495 15.4787 16.8197L5.31849 0.923842C5.15461 0.670581 4.88645 0.521606 4.5885 0.521606H0.953455C0.26816 0.521606 0 0.834457 0 1.46016V23.181C0 23.6876 0.32775 24.0898 0.864068 24.0898H4.05218C4.60339 24.0898 4.94604 23.7322 4.94604 23.181V9.83268H4.91625Z"
        fill={color}
      />
      <path
        d="M172.948 24.3726C168.195 24.3726 164.277 20.4693 164.203 15.6723C164.158 13.2886 165.112 10.9497 166.78 9.25137C168.404 7.59773 170.534 6.68896 172.784 6.68896C176.419 6.68896 179.398 8.49158 180.978 11.6201C181.052 11.7542 181.052 11.9181 180.993 12.067C180.933 12.216 180.814 12.3203 180.665 12.3799L177.209 13.5419C177.134 13.5717 177.06 13.5717 177 13.5717C176.776 13.5717 176.553 13.4525 176.434 13.2589C175.6 12.0223 174.467 11.3966 173.052 11.3966C170.773 11.3966 168.985 13.1993 168.985 15.5084C168.985 17.8027 170.802 19.65 173.052 19.65C174.467 19.65 175.644 19.0094 176.434 17.7878C176.568 17.5792 176.747 17.4749 176.985 17.4749C177.134 17.4749 177.298 17.5047 177.492 17.5792L180.337 18.5475C180.605 18.622 180.799 18.8008 180.903 19.0243C180.993 19.2179 180.978 19.4414 180.873 19.6351C179.25 22.6146 176.285 24.3726 172.948 24.3726Z"
        fill={color}
      />
      <path
        d="M115.755 24.0895C116.292 24.0895 116.59 23.732 116.59 23.2403V19.9479C116.59 19.3967 116.321 19.0988 115.621 19.0839H114.653C114.072 19.0839 113.744 18.7561 113.744 18.1453V1.47478C113.744 0.983159 113.461 0.610718 112.88 0.610718H109.677C109.021 0.610718 108.724 0.893772 108.724 1.47478V18.7561C108.724 22.5401 110.109 24.0895 113.476 24.0895H115.755Z"
        fill={color}
      />
      <path
        d="M207.361 20.0523C207.793 20.3949 207.778 20.9163 207.331 21.4229C205.633 23.3745 203.756 24.4024 200.002 24.4024C195.145 24.4024 191.182 20.4247 191.182 15.5234C191.182 10.6369 195.13 6.58473 200.002 6.64433C205.365 6.71881 208.791 10.6965 208.791 15.7319C208.791 16.3278 208.478 16.6258 207.883 16.6258H196.143C196.277 18.5774 197.812 20.0523 200.002 20.0523C202.058 20.0523 203.115 19.0988 203.816 18.3539C204.203 17.9219 204.65 17.9219 205.052 18.2347L207.361 20.0523ZM200.166 10.5326C198.154 10.5326 196.724 11.6946 196.426 13.3334H203.741C203.413 11.7691 202.087 10.5326 200.166 10.5326Z"
        fill={color}
      />
      <path
        d="M190.05 24.0895C190.586 24.0895 190.884 23.732 190.884 23.2403V19.9479C190.884 19.3967 190.616 19.0988 189.916 19.0839H188.948C188.367 19.0839 188.039 18.7561 188.039 18.1453V1.47478C188.039 0.983159 187.756 0.610718 187.175 0.610718H183.972C183.316 0.610718 183.018 0.893772 183.018 1.47478V18.7561C183.018 22.5401 184.404 24.0895 187.771 24.0895H190.05Z"
        fill={color}
      />
      <path
        d="M55.5983 22.5551C55.0321 23.0616 53.8403 24.2683 50.4883 24.2683C48.0153 24.2683 44.8868 22.5253 44.9166 17.7729V11.4116C44.9166 11.3371 44.857 11.2775 44.7825 11.2775H43.4417C42.8905 11.2775 42.5181 10.9647 42.5181 10.3836L42.533 7.80634C42.533 7.35941 42.7862 6.97207 43.4566 6.97207H44.7974C44.8719 6.97207 44.9315 6.91248 44.9315 6.83799V1.41523C44.9315 0.834214 45.2443 0.521362 45.8254 0.521362H49.1029C49.6243 0.521362 49.8775 0.774621 49.8775 1.29604V6.83799C49.8775 6.91248 49.9371 6.97207 50.0116 6.97207H54.0191C54.6001 6.97207 54.9279 7.29982 54.9279 7.89573L54.913 10.339C54.913 10.9349 54.5852 11.2626 54.0042 11.2626H50.0116C49.9371 11.2626 49.8775 11.3222 49.8775 11.3967V17.4303C49.8775 18.8158 50.6671 19.6649 51.9483 19.6649C52.4399 19.6649 52.8124 19.5606 53.1252 19.3223C53.4977 19.0392 53.9893 19.069 54.3022 19.4415C54.7491 19.9927 55.8068 21.3931 55.8068 21.3931C56.0452 21.7804 55.9558 22.2273 55.5983 22.5551Z"
        fill={color}
      />
      <path
        d="M209.968 20.0523C209.522 20.395 209.596 20.9015 209.79 21.1846C210.415 22.1231 212.531 24.4174 217 24.4174C222.05 24.4174 224.583 21.7656 224.583 19.084C224.583 15.8363 222.617 14.2869 217.477 13.0355C215.406 12.529 214.9 12.1268 214.9 11.65C214.9 11.1435 215.391 10.6668 216.613 10.6668C218.237 10.6668 219.414 11.0988 221.276 12.1715C221.693 12.4098 222.125 12.4396 222.483 11.9629L223.957 10.0411C224.315 9.53456 224.091 9.08763 223.674 8.78967C221.782 7.46378 219.697 6.67419 217.015 6.67419C212.769 6.67419 210.058 9.11742 210.058 12.1268C210.058 14.9275 211.89 16.6408 216.002 17.5942C218.415 18.1603 219.548 18.3987 219.548 19.1287C219.548 19.7395 218.892 20.3205 217.119 20.3205C215.347 20.3205 213.842 19.2777 213.157 18.5477C212.829 18.1901 212.352 18.1603 211.935 18.4881C211.92 18.4881 210.117 19.9332 209.968 20.0523Z"
        fill={color}
      />
      <path
        d="M230.767 0C227.354 0 224.583 2.77139 224.583 6.18348C224.583 9.59558 227.354 12.367 230.767 12.367C234.179 12.367 236.95 9.59558 236.95 6.18348C236.95 2.77139 234.179 0 230.767 0ZM230.767 10.0128C228.651 10.0128 226.922 8.29928 226.922 6.16858C226.922 4.05279 228.636 2.3244 230.767 2.3244C232.882 2.3244 234.611 4.03789 234.611 6.16858C234.611 8.29928 232.882 10.0128 230.767 10.0128Z"
        fill={color}
      />
    </svg>
  )

  if (link) {
    return (
      <a uid={uid} href={href || localizedHomeUrl}>
        {svg}
      </a>
    )
  }

  return svg
}

export default Logo
