export interface NewsletterAccountInput {
  email: string
  source?: string
  campaign?: string
  consentTo?: string
}

class GtmService {
  init(): void {
    window.dataLayer ||= []
  }

  trackImpression(): void {
    window.dataLayer.push({
      event: 'pageViewed',
      pageViewed: document.location.pathname,
      hash: document.location.hash,
      'View Type': 'Website',
    })

    if (document.location.hash) {
      window.dataLayer.push({
        event: 'pageViewHash',
        pageViewed: document.location.pathname,
        hash: document.location.hash,
        'View Type': 'Website',
      })
    }
  }

  trackLeadCapture({ email, source, campaign, consentTo }: NewsletterAccountInput): void {
    window.dataLayer.push({
      event: 'LeadCapture',
      email,
      source,
      campaign,
      consentTo,
    })
  }
}

export const gtmService = new GtmService()
