import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { navigate } from 'gatsby'
import { mixpanelService } from '../../srv/mixpanel.service'
import { Thunk } from '..'

async function delay(ms: number): Promise<void> {
  return await new Promise(resolve => setTimeout(resolve, ms))
}

export interface CyclemattersSearchState {
  query: string
}

const initialState: CyclemattersSearchState = {
  query: '',
}

const slice = createSlice({
  name: 'cyclemattersSearch',

  initialState,

  reducers: {
    search(cyclemattersSearch: CyclemattersSearchState, action: PayloadAction<string>) {
      cyclemattersSearch.query = action.payload
    },
  },
})

export const searchCyclematters =
  (query: string): Thunk =>
  async dispatch => {
    if (query !== '') {
      const pathname = window.location.pathname
      if (pathname.match(/\/cyclematters\/search/)) {
        window.history.pushState(null, 'Cyclematters Search', `?q=${query}`)
      } else {
        await navigate(`/cyclematters/search?q=${query}`)
        await delay(100)
      }

      mixpanelService.track('CyclemattersSearch', {
        query,
      })
    }

    dispatch(slice.actions.search(query))
    await delay(1000)
  }

export const selectCyclemattersSearch = ({
  cyclemattersSearch,
}: {
  cyclemattersSearch: CyclemattersSearchState
}): CyclemattersSearchState => cyclemattersSearch

export const selectCyclemattersSearchQuery = createSelector(
  [selectCyclemattersSearch],
  (cyclemattersSearch): string => {
    return cyclemattersSearch.query
  },
)

export default slice
