import { getFetcher, StringMap, UnixTimestampMillisNumber } from '@naturalcycles/js-lib'
import { BackendResponseFM, BackendResponseFMResp } from '@naturalcycles/shared'
import { getUtmQueryParams } from '@src/helpers/queryParams'
import { onBackendResponse, store } from '@src/store'
import { mixpanelService } from './mixpanel.service'

const fetcher = getFetcher({
  baseUrl: process.env['GATSBY_NCBACKEND3_URL'],
  credentials: 'omit', // to avoid CORS preflight
})

interface InitStorage {
  data: BackendResponseFM
  timestamp: UnixTimestampMillisNumber
}

export class WebInitService {
  private storageKey = 'initData'
  private validityInMs = 24 * 60 * 60 * 1000 // 24h

  async init() {
    const storage = this.getStorageData()

    if (storage) {
      store.dispatch(onBackendResponse(storage.data))
      return
    }

    const distinctId = mixpanelService.getDistinctId()
    const utms = getUtmQueryParams()
    const { backendResponse } = await webInitService.getWebInitData(distinctId, utms)

    if (!backendResponse) {
      return
    }

    store.dispatch(onBackendResponse(backendResponse))
    const data: InitStorage = {
      timestamp: Date.now(),
      data: backendResponse,
    }
    window.localStorage.setItem(this.storageKey, JSON.stringify(data))
  }

  getStorageData(): InitStorage | null {
    const storage = window.localStorage.getItem(this.storageKey)

    if (!storage) return null

    const parsed = JSON.parse(storage)
    const now = Date.now()
    const savedTimestamp = parsed.timestamp

    if (now - savedTimestamp > this.validityInMs) {
      return null
    }

    return parsed
  }

  async getWebInitData(
    distinctId: string,
    searchParams: StringMap,
  ): Promise<BackendResponseFMResp> {
    return await fetcher.get('api/v3/app/webInit', {
      searchParams: {
        ...searchParams,
        // Kirill: Avoiding CORS preflight by sending everything over searchParams and not http headers
        distinctId,
      },
    })
  }
}

export const webInitService = new WebInitService()
