import { useLocation } from '@reach/router'
import { brandPurple } from '@src/cnst/colors.cnst'
import BurgerIcon from '@src/components/burger-icon/BurgerIcon.component'
import DesktopMenu from '@src/components/desktop-menu/DesktopMenu.component'
import { HeaderLayouts, HeaderThemes } from '@src/components/header/Header.component'
import Logo from '@src/components/logo/Logo.component'
import SignupButton from '@src/components/signup-button/SignupButton.component'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { PageTypes, usePageType } from '@src/hooks/usePageType'
import { useTranslation } from '@src/hooks/useTranslation'
import { MobileMenuStatuses, selectMobileMenuStatus } from '@src/store/mobileMenu/mobileMenu.slice'
import classNames from 'classnames'
import React from 'react'
import { useScrollYPosition } from 'react-use-scroll-position'
import { ButtonThemes } from '../button/Button.component'
import { ClientOnly } from '../client-only/ClientOnly.component'
import styles from './HeaderStandard.module.scss'

interface HeaderStandardProps {
  layout: HeaderLayouts
  headerTheme?: 'white' | 'default'
}

export const HeaderStandard = ({
  layout = HeaderLayouts.Standard,
  headerTheme,
}: HeaderStandardProps): React.ReactElement => {
  const [oura, setOura] = React.useState(false)
  const [ouraDiscount, setOuraDiscount] = React.useState('oura10')
  const reduxCode = useAppSelector(state => state.discountCode.code)
  const mobileMenuStatus = useAppSelector(selectMobileMenuStatus)
  const translate = useTranslation()
  const pageType = usePageType()

  const mobileMenuOpen = mobileMenuStatus === MobileMenuStatuses.Open

  let burgerColor = '#fff'
  let logoColor = '#fff'
  let signupColor: ButtonThemes = 'brandBlue'
  let theme = HeaderThemes.SupportPurple

  const [scrolled, setScrolled] = React.useState(false)
  const [code, setCode] = React.useState<string>()
  const { pathname } = useLocation()

  if (headerTheme === 'white' && !mobileMenuOpen && pageType === PageTypes.StandardHome) {
    logoColor = brandPurple
    burgerColor = brandPurple
    signupColor = 'brandPurple'
    theme = HeaderThemes.White
  } else if (
    [HeaderLayouts.StandardHome, HeaderLayouts.StandardHomeDiscountBanner].includes(layout) &&
    !mobileMenuOpen &&
    !scrolled
  ) {
    burgerColor = brandPurple
    logoColor = brandPurple
    signupColor = 'brandPurple'
    theme = HeaderThemes.TransparentSupportPurple
  } else if (
    [HeaderLayouts.StandardHome, HeaderLayouts.StandardHomeDiscountBanner].includes(layout) &&
    mobileMenuOpen
  ) {
    theme = HeaderThemes.SupportPurple
    signupColor = 'brandBlue'
  }

  const position = useScrollYPosition()

  React.useEffect(() => {
    setCode(reduxCode || sessionStorage.getItem('discountCode') || '')
  }, [reduxCode])

  React.useEffect(() => {
    if (pathname.includes('oura')) {
      setOura(true)
      if (code) {
        const check = /[Oo]ura/.test(code)
        if (check) {
          setOuraDiscount(code)
        }
      }
    } else {
      setOura(false)
    }
  }, [pathname, code])

  React.useEffect(() => {
    if (position > 0) {
      setScrolled(true)
    } else if (position < 1) {
      setScrolled(false)
    }
  }, [position])

  const barClassName = classNames(styles.HeaderStandard, styles[`HeaderStandard__theme${theme}`])
  const layoutClassName = classNames(
    styles.HeaderStandard__layout,
    styles[`HeaderStandard__layout${layout}`],
  )

  return (
    <>
      <ClientOnly>
        <div className={barClassName}>
          <div className={layoutClassName}>
            <div className={styles.HeaderStandard__burger}>
              <BurgerIcon color={burgerColor} />
            </div>

            <div className={styles.HeaderStandard__logo}>
              <Logo color={logoColor} uid="header-standard-logo" />
            </div>

            <div className={styles.HeaderStandard__menu}>
              <DesktopMenu theme={theme} />
            </div>

            <div className={styles.HeaderStandard__button}>
              <SignupButton
                uid="header-signup-button"
                size="small"
                theme={signupColor}
                code={oura ? ouraDiscount : undefined}
                ignoreConfirmation={oura ? true : undefined}
              >
                {translate('nav-signup')}
              </SignupButton>
            </div>
          </div>
        </div>
      </ClientOnly>
    </>
  )
}
