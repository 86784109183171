import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mixpanelService } from '@src/srv/mixpanel.service'
import { Thunk } from '@src/store'

export interface DiscountCodeState {
  code: string
  percentage: string
  name: string
  referral: string
}

const initialState: DiscountCodeState = {
  code: '',
  percentage: '',
  name: '',
  referral: '',
}

const slice = createSlice({
  name: 'discountCode',

  initialState,

  reducers: {
    setDiscountCode(discountCode: DiscountCodeState, action: PayloadAction<string>) {
      discountCode.code = action.payload
    },

    setDiscountPercentage(discountPercentage: DiscountCodeState, action: PayloadAction<string>) {
      discountPercentage.percentage = action.payload
    },

    setDiscountName(discountName: DiscountCodeState, action: PayloadAction<string>) {
      discountName.name = action.payload
    },

    setDiscountReferral(discountReferral: DiscountCodeState, action: PayloadAction<string>) {
      discountReferral.referral = action.payload
    },
  },
})

export const setDiscountCode =
  (code: string, source: string): Thunk =>
  async dispatch => {
    dispatch(slice.actions.setDiscountCode(code))
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('discountCode', code)
    }

    mixpanelService.track('SaveDiscountCode', { code, source })
  }

export const setDiscountPercentage =
  (percentage: string): Thunk =>
  async dispatch => {
    dispatch(slice.actions.setDiscountPercentage(percentage))
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('discountPercentage', percentage)
    }
  }

export const setDiscountName =
  (name: string, decode = false): Thunk =>
  async dispatch => {
    let savedName = name

    if (decode) {
      savedName = window.atob(name)
    }

    dispatch(slice.actions.setDiscountName(savedName))
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('discountName', savedName)
    }
  }

export const setDiscountReferral =
  (referral: string): Thunk =>
  async dispatch => {
    dispatch(slice.actions.setDiscountReferral(referral))
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('discountReferral', referral)
    }
  }

export default slice
