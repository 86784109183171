import { SupportedLocale } from '@src/cnst/translations.cnst'
import AppStoreIcon from '@src/components/app-store-icon/AppStoreIcon.component'
import FooterHeading from '@src/components/footer-heading/FooterHeading.component'
import FooterLinks from '@src/components/footer-links/FooterLinks.component'
import GooglePlayIcon from '@src/components/google-play-icon/GooglePlayIcon.component'
import LanguagePicker from '@src/components/language-picker/LanguagePicker.component'
import LanguageContext from '@src/context/language.context'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { NavMenuFooterPlacements, useNavMenuFooter } from '@src/hooks/useNavMenuFooter'
import {
  ConsumerPageTypes,
  PageTypes,
  PressPageTypes,
  ShopPageTypes,
  usePageType,
} from '@src/hooks/usePageType'
import { useTranslation } from '@src/hooks/useTranslation'
import { selectGeolocationCountry } from '@src/store/geolocation/geolocation.slice'
import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { ClientOnly } from '../client-only/ClientOnly.component'
import { RotatingUsp } from '../rotating-usp/RotatingUsp.component'
import styles from './Footer.module.scss'

interface FooterProps {
  theme?: 'grey' | 'purple'
}

const noFooterPageTypes = [PageTypes.Campaign, PageTypes.WebView]
const consumerFooterPageTypes = [...ConsumerPageTypes, ...PressPageTypes, ...ShopPageTypes]

const usps: Partial<Record<SupportedLocale, string[]>> = {
  [SupportedLocale.enUS]: [
    'Birth control.',
    '100% Hormone-free.',
    'Regulated & certified.',
    'Prescription-free.',
    'Tailored to you.',
    'Effective.',
  ],
  [SupportedLocale.svSE]: [
    'Preventivmedel.',
    '100% Hormonfri.',
    'Reglerad och certifierad.',
    'Receptfri.',
    'Skräddarsydd för dig.',
    'Effektiv.',
  ],
  [SupportedLocale.ptBR]: [
    'Controle de natalidade.',
    '100% Livre de hormônios.',
    'Regulamentado e certificado.',
    'Sem prescrição médica.',
    'Personalizado para você.',
    'Eficaz.',
  ],
  [SupportedLocale.esUS]: [
    'Método contracetivo.',
    '100% Libre de hormonas.',
    'Regulado y certificado.',
    'Sin necesidad de receta.',
    'Personalizado para ti.',
    'Efectivo.',
  ],
}

export const Footer = ({ theme = 'grey' }: FooterProps): React.ReactElement => {
  const pageType = usePageType()
  const translate = useTranslation()
  const { locale, fallbackLocale } = React.useContext(LanguageContext)
  const country = useAppSelector(selectGeolocationCountry)

  const consumerAppFooter = useNavMenuFooter(NavMenuFooterPlacements.FooterApp, country)
  const consumerCompanyFooter = useNavMenuFooter(NavMenuFooterPlacements.FooterCompany, country)
  const consumerScienceResearchFooter = useNavMenuFooter(
    NavMenuFooterPlacements.FooterScienceResearch,
    country,
  )
  const consumerSupportFooter = useNavMenuFooter(NavMenuFooterPlacements.FooterSupport, country)

  // test 227
  const uspsList = usps[locale] || usps[fallbackLocale!] || []

  const loginUrl = (locale: string) => {
    if (locale === 'sv-SE') {
      return `${process.env['APP_URL']}?lang=sv&webLogin=1`
    }
    if (locale === 'es-US') {
      return `${process.env['APP_URL']}?lang=es&webLogin=1`
    }
    if (locale === 'pt-BR') {
      return `${process.env['APP_URL']}?lang=pt&webLogin=1`
    }
    if (locale === 'en-CA') {
      return `${process.env['APP_URL']}?lang=en-CA&webLogin=1`
    }
    return `${process.env['APP_URL']}?webLogin=1`
  }

  const consumerFooterPageContent = (
    <>
      <div className={styles.Footer__app}>
        <FooterHeading theme={theme}>{translate('nav-app')}</FooterHeading>
        <FooterLinks theme={theme}>{consumerAppFooter}</FooterLinks>
      </div>
      <div className={styles.Footer__scienceResearch}>
        <FooterHeading theme={theme}>{translate('nav-science-research')}</FooterHeading>
        <FooterLinks theme={theme}>{consumerScienceResearchFooter}</FooterLinks>
      </div>
      <div className={styles.Footer__company}>
        <FooterHeading theme={theme}>{translate('nav-company')}</FooterHeading>
        <FooterLinks theme={theme}>{consumerCompanyFooter}</FooterLinks>
      </div>
      <div className={styles.Footer__support}>
        <FooterHeading theme={theme}>{translate('nav-nc-support')}</FooterHeading>
        <FooterLinks theme={theme}>{consumerSupportFooter}</FooterLinks>
      </div>
    </>
  )
  return (
    <ClientOnly>
      {!noFooterPageTypes.includes(pageType) && (
        <>
          <footer className={styles.Footer} uid="site-footer">
            <div
              className={classNames(styles.Footer__widgets, {
                [styles.Footer__consumerWidgets]: consumerFooterPageTypes.includes(pageType),
              })}
            >
              {consumerFooterPageTypes.includes(pageType) && consumerFooterPageContent}

              <div className={styles.Footer__downloadApp}>
                <FooterHeading theme={theme}>{translate('nav-download-app')}</FooterHeading>
                <AppStoreIcon href="https://apps.apple.com/us/app/natural-cycles-birth-control/id765535549?ct=website_footer&fallback=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fnatural-cycles-fertility-app%2Fid765535549" />
                <GooglePlayIcon href="https://play.google.com/store/apps/details?id=com.naturalcycles.cordova&utm_campaign=website_footer" />
              </div>
            </div>

            <hr className={styles.Footer__hr} />
            <div className={styles.Footer__midSection}>
              <div className={styles.Footer__socialIcons}>
                <a
                  className={styles.Footer__socialLink}
                  target="_blank"
                  rel="noopener"
                  href="https://www.instagram.com/naturalcycles"
                >
                  <StaticImage
                    placeholder="none"
                    loading="lazy"
                    alt="Instagram"
                    src="../../lazy-load-images/instagram.svg"
                    width={24}
                    height={24}
                  />
                </a>
                <a target="_blank" rel="noopener" href="https://www.facebook.com/NaturalCyclesNC">
                  <StaticImage
                    placeholder="none"
                    loading="lazy"
                    alt="Facebook"
                    src="../../lazy-load-images/facebook.svg"
                    width={24}
                    height={24}
                  />
                </a>
                <a target="_blank" rel="noopener" href="https://twitter.com/NaturalCycles">
                  <StaticImage
                    placeholder="none"
                    loading="lazy"
                    alt="Twitter"
                    src="../../lazy-load-images/twitter.svg"
                    width={24}
                    height={24}
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://www.linkedin.com/company/naturalcycles"
                >
                  <StaticImage
                    placeholder="none"
                    loading="lazy"
                    alt="LinkedIn"
                    src="../../lazy-load-images/linkedin.svg"
                    width={24}
                    height={24}
                  />
                </a>
                <a target="_blank" rel="noopener" href="https://www.tiktok.com/@naturalcycles">
                  <StaticImage
                    placeholder="none"
                    loading="lazy"
                    alt="TikTok"
                    src="../../lazy-load-images/tiktok.svg"
                    width={24}
                    height={24}
                  />
                </a>
                <a target="_blank" rel="noopener" href="https://www.youtube.com/c/naturalcycles">
                  <StaticImage
                    placeholder="none"
                    loading="lazy"
                    alt="YouTube"
                    src="../../lazy-load-images/youtube.svg"
                    className={styles.Footer__youtubeIcon}
                  />
                </a>
              </div>

              <div className={styles.Footer__langPicker}>
                <LanguagePicker />
              </div>
            </div>

            {pageType === PageTypes.StandardHome && (
              <RotatingUsp backgroundColor="default" uspsList={uspsList} theme="footer" />
            )}

            <div className={styles.Footer__bottom}>
              <div className={styles.Footer__desktopContainer}>
                <ul className={styles.Footer__legalLinks}>
                  <li>
                    <a
                      uid="footer-nav-terms"
                      className={styles.Footer__legalLink}
                      href="/other/legal/terms"
                    >
                      {translate('nav-terms')}
                    </a>
                  </li>
                  <li>
                    <a
                      uid="footer-nav-login"
                      className={styles.Footer__legalLink}
                      href={loginUrl(locale)}
                    >
                      {translate('nav-login')}
                    </a>
                  </li>
                  <li>
                    <a
                      uid="footer-nav-privacy"
                      className={styles.Footer__legalLink}
                      href="/other/legal/privacy"
                    >
                      {translate('nav-privacy')}
                    </a>
                  </li>
                  <li>
                    <a
                      uid="footer-nav-cookies"
                      className={styles.Footer__legalLink}
                      href="/other/legal/cookies"
                    >
                      {translate('nav-cookies')}
                    </a>
                  </li>
                </ul>

                <div className={styles.Footer__companyName}>
                  {locale === 'sv-SE' ? 'NaturalCycles Nordic AB ©' : 'Natural Cycles USA Corp'}
                </div>
              </div>
              {pageType === PageTypes.StandardHome && (
                <div className={styles.Footer___trailingButtonSpace} />
              )}
            </div>
          </footer>
        </>
      )}
    </ClientOnly>
  )
}
