import { getTrafficOrigin } from './trafficOriginUtil'

export const getSignupURL = (path: string) => new URL(`${process.env['GATSBY_SIGNUP_URL']}/${path}`)
export const addUTMParamsToUrl = (url: URL, document: Document, customSource: string): void => {
  const origin = getTrafficOrigin(document)
  url.searchParams.set('utm_source_web_medium', origin.utm_medium)
  if (customSource) url.searchParams.set('utm_source_web', customSource)
}
export const getTrackedSignupURL = (
  path: string,
  document: Document,
  customSource: string,
): URL => {
  const url = getSignupURL(path)
  addUTMParamsToUrl(url, document, customSource)

  return url
}
