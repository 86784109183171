import { Assignments, ExperimentState } from '@naturalcycles/shared'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isE2eTest } from '@src/cnst/test.cnst'

interface ExperimentStateFM {
  experiment: ExperimentState | null
}

const initialState: ExperimentStateFM = {
  experiment: null,
}

const slice = createSlice({
  name: 'experiment',

  initialState,

  reducers: {
    replaceExperiment(state: ExperimentStateFM, action: PayloadAction<ExperimentState | null>) {
      if (action.payload == null) {
        state.experiment = null
        return
      }

      const { assignments, id } = action.payload

      if (isE2eTest()) {
        console.log('E2E test detected, set empty assignments')
        state.experiment = {
          assignments: {},
          id,
        }
        return
      }

      state.experiment = {
        assignments,
        id,
      }
    },
  },
})

export const selectStore = ({
  experiment,
}: {
  experiment: ExperimentStateFM
}): ExperimentStateFM => {
  return experiment
}

export const selectAssignments = createSelector([selectStore], (state): Assignments | null => {
  return state?.experiment?.assignments || null
})

export default slice
