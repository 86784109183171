class BotDetectionService {
  isBot(): boolean {
    if (typeof navigator === 'undefined' || typeof window === 'undefined') {
      // probably just running during the SSR build, ignore
      return false
    }

    if (window.hasOwnProperty('callPhantom') || window.hasOwnProperty('_phantom')) {
      // phantomjs puts these properties on the window object by default so this
      // is a super simple + easy check
      return true
    }

    if (navigator.userAgent.includes('Android')) {
      // previous bot detection attempts have accidentally filtered out all
      // android events so this check aborts bot detection early for anything
      // claiming to be an android device
      return false
    }

    if (!navigator.userAgent.includes('Chrome')) {
      // the bots we're interested in detecting are using a Chrome user agent
      // so we're not interested in doing any further bot detection beyond this
      // point for non-Chrome user agents
      return false
    }

    if (navigator.hasOwnProperty('webdriver')) {
      // this is the simplest test for headless Chrome as it adds this property
      // to the window object by default. doesn't currently work for our
      // Ashburn problem but worth having anyway.
      return true
    }

    if ((navigator.languages as any) === '') {
      // in headless Chrome navigator.languages returns an empty string
      // instead of an array
      // https://antoinevastel.com/bot%20detection/2018/01/17/detect-chrome-headless-v2.html
      return true
    }

    if (navigator.plugins.length === 0) {
      // normally this is full of things like the built-in Chrome PDF viewer
      // plugin but is an empty array by default in headless Chrome
      // https://antoinevastel.com/bot%20detection/2018/01/17/detect-chrome-headless-v2.html
      return true
    }

    if (!(window as any).chrome) {
      // this is "the chrome test" from https://intoli.com/blog/not-possible-to-block-chrome-headless/
      // this property is for some reason not present by default in headless chrome
      return true
    }

    return false
  }
}

export const botDetectionService = new BotDetectionService()
