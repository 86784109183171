import { SupportedLocale } from '@src/cnst/translations.cnst'
import LanguageContext from '@src/context/language.context'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { useTranslation } from '@src/hooks/useTranslation'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import styles from './BannerDiscountCode.module.scss'

export interface BannerDiscountCodeProps {
  page?: 'influencer' | 'regular'
}

export const BannerDiscountCode = ({ page }: BannerDiscountCodeProps): ReactElement => {
  const [display, setDisplay] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)

  const [code, setCode] = useState<string>()
  const [percentage, setPercentage] = useState<string>()
  const [name, setName] = useState<string>()

  // Get values from Redux
  const reduxCode = useAppSelector(state => state.discountCode.code)
  const reduxPercentage = useAppSelector(state => state.discountCode.percentage)
  const reduxName = useAppSelector(state => state.discountCode.name)
  const { locale } = useContext(LanguageContext)

  useEffect(() => {
    // Use values saved in the Session Storage if no recent value was triggered
    setCode(reduxCode || window.sessionStorage.getItem('discountCode') || '')
    setPercentage(reduxPercentage || window.sessionStorage.getItem('discountPercentage') || '')
    setName(reduxName || window.sessionStorage.getItem('discountName') || '')
  }, [reduxCode, reduxPercentage, reduxName])

  const translate = useTranslation()

  useEffect(() => {
    if (code) {
      setDisplay(true)
    }
  }, [code])

  if (display) {
    return (
      <div className={styles.BannerDiscountCode}>
        <div className={styles.BannerDiscountCode__layout}>
          <p className={styles.BannerDiscountCode__text}>
            {page === 'influencer' ? (
              translate('discount-influencer')
            ) : percentage ? (
              <>
                {translate('discount-saved-1')}{' '}
                <span className={styles.BannerDiscountCode__textHighlight}>
                  {locale !== SupportedLocale.enUS && percentage.includes('OFF')
                    ? percentage.slice(0, 3)
                    : percentage}
                </span>{' '}
                {translate('discount-saved-2')}
              </>
            ) : (
              <>{translate('discount-offer')}</>
            )}
          </p>
          {!page && (
            <>
              <button
                onClick={() => setShowDropdown(!showDropdown)}
                className={
                  showDropdown
                    ? styles.BannerDiscountCode__arrowUp
                    : styles.BannerDiscountCode__arrowDown
                }
                aria-label="more discount information"
              >
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img src="/icons/faq-chevron-black.svg" aria-hidden={true} />
              </button>

              <p
                className={
                  !showDropdown
                    ? styles.BannerDiscountCode__dropdownHidden
                    : styles.BannerDiscountCode__dropdownVisible
                }
              >
                {name ? (
                  <>
                    {translate('discount-referral-code-1')}{' '}
                    <span className={styles.BannerDiscountCode__dropdownHighlightName}>{name}</span>{' '}
                    {translate('discount-referral-code-2')}
                  </>
                ) : (
                  <>
                    {translate('discount-saved-code-1')}{' '}
                    <span className={styles.BannerDiscountCode__dropdownHighlightCode}>{code}</span>{' '}
                    {translate('discount-saved-code-2')}
                  </>
                )}
              </p>
            </>
          )}
        </div>
      </div>
    )
  }
  return <></>
}
