import BurgerIcon from '@src/components/burger-icon/BurgerIcon.component'
import DesktopMenu from '@src/components/desktop-menu/DesktopMenu.component'
import { HeaderThemes } from '@src/components/header/Header.component'
import Logo from '@src/components/logo/Logo.component'
import React from 'react'
import styles from './HeaderPress.module.scss'

interface HeaderPressProps {}

const HeaderPress = ({}: HeaderPressProps): React.ReactElement => {
  return (
    <div className={styles.HeaderPress}>
      <div className={styles.HeaderPress__layout}>
        <div className={styles.HeaderPress__burger}>
          <BurgerIcon color="white" />
        </div>

        <div className={styles.HeaderPress__logo}>
          <Logo color="white" uid="header-press-logo" />
        </div>

        <div className={styles.HeaderPress__menu}>
          <DesktopMenu theme={HeaderThemes.SupportPurple} />
        </div>

        <div className={styles.HeaderPress__pressContact}>
          <a className={styles.HeaderPress__pressContactLink} href="mailto:press@naturalcycles.com">
            Lauren Hanafin press@naturalcycles.com
          </a>
        </div>
      </div>
    </div>
  )
}

export default HeaderPress
