import type { StringMap } from '@naturalcycles/js-lib'
import { BackendResponseFM } from '@naturalcycles/shared'
import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit'
import { ShippingCurrency } from '@src/shop/cnst/shopify.cnst'
import { userLocaleSlice } from '@src/store/userLocale.slice'
import Cookies from 'js-cookie'
import shopify, { ShopifyCart } from '../shop/store/shopify.slice'
import cyclemattersSearch from './cyclemattersSearch/cyclemattersSearch.slice'
import discountCode from './discountCode/discountCode.slice'
import experiment from './experiment/experiment.slice'
import geolocation, { GeolocationCountries } from './geolocation/geolocation.slice'
import mobileMenu from './mobileMenu/mobileMenu.slice'
import pathname from './pathname/pathname.slice'
import popup, { PopupState, PopupStatuses } from './popup/popup.slice'

const reducer = combineReducers({
  mobileMenu: mobileMenu.reducer,
  geolocation: geolocation.reducer,
  popup: popup.reducer,
  shopify: shopify.reducer,
  discountCode: discountCode.reducer,
  cyclemattersSearch: cyclemattersSearch.reducer,
  pathname: pathname.reducer,
  experiment: experiment.reducer,
  userLocale: userLocaleSlice.reducer,
})

// If the user is coming from the app or with a discount code parameter we disable all popups
const popupFreeSources = ['NCApp', 'check-websignup', 'optimove', 'attentive']
let popupStatus = PopupStatuses.Closed

if (typeof window !== 'undefined') {
  const searchParams = new URLSearchParams(window.location.search)
  const utms: StringMap = Object.fromEntries(
    [...searchParams.entries()].filter(([key, value]) => {
      return key.startsWith('utm') && !!value
    }),
  )
  const hasDiscountCode = searchParams.has('code')

  if (utms['utm_source'] && (popupFreeSources.includes(utms['utm_source']) || hasDiscountCode)) {
    popupStatus = PopupStatuses.Disabled
  }

  // check utm and create a cookie for signup to parse
  if (Object.keys(utms).length) {
    const utmParams = JSON.stringify(utms)
    Cookies.set('utms', utmParams, { expires: 30, domain: 'naturalcycles.com' })
  }
}

interface PreloadedStateProps {
  popup: PopupState
  shopify:
    | {
        cart: ShopifyCart
      }
    | any
}

const preloadedState: PreloadedStateProps = {
  popup: {
    status: popupStatus,
    impressions: {},
  },
  shopify: {
    cart: {},
    currency: ShippingCurrency.ROW,
    alteredRegion: false,
  },
}

if (typeof window !== 'undefined') {
  Object.entries(localStorage).forEach(([key, value]) => {
    const popupCampaign = /<Popup name="([^"]+)" \/>/.exec(key)
    if (popupCampaign) {
      ;(preloadedState.popup.impressions as any)[popupCampaign[1]!] = Number.parseInt(value, 10)
    }
  })

  const cart = sessionStorage.getItem('cart')
  if (cart) {
    preloadedState.shopify.cart = JSON.parse(cart)
  }
}

export const store = configureStore({
  reducer,
  preloadedState,
})

export const onBackendResponse =
  (br: BackendResponseFM): Thunk =>
  dispatch => {
    if (br.experiment) {
      dispatch(experiment.actions.replaceExperiment(br.experiment))
    }

    if (br.userLocale) {
      dispatch(userLocaleSlice.actions.replace(br.userLocale))

      // Right now we're basing our "geolocation" service upon webInit `br.userLocale.country`
      // it does geolocationDone.resolve() inside
      dispatch(geolocation.actions.success(br.userLocale.country as GeolocationCountries))
    }
  }

export type RootState = ReturnType<typeof reducer>
export type Thunk = ThunkAction<void, RootState, unknown, Action<string>>
export type Dispatch = ThunkDispatch<RootState, unknown, Action<string>>

const getStore = () => store
export default getStore
