import { ClientOnly } from '@src/components/client-only/ClientOnly.component'
import { NavMenu } from '@src/hooks/useNavMenuFooter'
import React from 'react'
import styles from './FooterLinks.module.scss'

interface FooterLinksProps {
  children: NavMenu
  theme?: 'grey' | 'purple'
}

const FooterLinks = ({ children, theme = 'grey' }: FooterLinksProps): React.ReactElement => {
  return (
    <ClientOnly>
      <ul className={styles.FooterLinks}>
        {children.map(navMenuItem => (
          <li key={navMenuItem.text}>
            <a
              uid={navMenuItem.uid}
              href={navMenuItem.href}
              className={
                theme === 'grey' ? styles.FooterLinks__link : styles.FooterLinks__linkPurpleTheme
              }
              aria-label={navMenuItem.text === 'FAQs' ? 'Frequently Asked Questions' : ''}
            >
              {navMenuItem.text}
            </a>
          </li>
        ))}
      </ul>
    </ClientOnly>
  )
}

export default FooterLinks
