import { _findKeyByValue, StringMap } from '@naturalcycles/js-lib'
import { useLocation } from '@reach/router'
import { useTranslation } from '@src/hooks/useTranslation'
import { mixpanelService } from '@src/srv/mixpanel.service'
import { SitemapContent } from '@src/srv/sitemap.service'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styles from './LanguagePicker.module.scss'

export interface LanguagePickerProps {}

const languages: StringMap = {
  enUs: 'English',
  esUs: 'Español',
  ptBr: 'Português',
  svSe: 'Svenska',
  enCa: 'English (CA)',
}

const LanguagePicker = ({}: LanguagePickerProps): React.ReactElement => {
  const { pathname } = useLocation()
  const translate = useTranslation()
  const [open, toggle] = React.useState(false)

  const onOpen = () => {
    toggle(!open)
    mixpanelService.trackClick('LanguagePicker__button')
  }

  const sitemap: SitemapContent[] = useStaticQuery(graphql`
    query {
      datoCmsSitemap {
        sitemapLinks {
          enUs
          esUs
          ptBr
          svSe
          enCa
        }
      }
    }
  `).datoCmsSitemap.sitemapLinks

  const enUsHomePath = pathname === '/'
  const path =
    pathname.endsWith('/') && !enUsHomePath ? pathname.slice(0, pathname.length - 1) : pathname
  const match = sitemap.find(entry => Object.values(entry).includes(path))

  if (!match) {
    return <></>
  }

  const menuClass = classNames(styles.LanguagePicker__menu, {
    [styles.LanguagePicker__openMenu]: open,
  })

  const key = _findKeyByValue(match, path)!

  const onKeyPress = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter' || event.code === 'Space') {
      toggle(!open)
    }
  }

  return (
    <div className={styles.LanguagePicker} onClick={() => toggle(!open)} uid="language-picker">
      <button
        className={styles.LanguagePicker__button}
        onClick={onOpen}
        onKeyPress={event => onKeyPress(event)}
        aria-expanded={open}
        aria-label={translate('lang-picker')}
      >
        <span className={styles.LanguagePicker__buttonText}>{languages[key]}</span>
        <span
          className={`${styles.LanguagePicker__buttonArrow} ${
            open ? styles.LanguagePicker__arrowTransition : ''
          }`}
        />
      </button>

      <ol className={menuClass}>
        {Object.entries(languages).map(([datoLocale, name]) => {
          const link = match[datoLocale]

          if (!link) {
            return
          }

          return (
            <li key={datoLocale}>
              <a
                uid={'language-option-' + datoLocale}
                onClick={() => toggle(false)}
                href={link}
                className={`${styles.LanguagePicker__link} ${
                  languages[key] === name ? styles.LanguagePicker__selected : ''
                }`}
              >
                {name}
              </a>
            </li>
          )
        })}
      </ol>
    </div>
  )
}

export default LanguagePicker
