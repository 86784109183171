import { UserLocale } from '@naturalcycles/shared'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: UserLocale = {} as UserLocale

export const userLocaleSlice = createSlice({
  name: 'userLocale',
  initialState,
  reducers: {
    replace(_state: UserLocale, action: PayloadAction<UserLocale>) {
      _state = action.payload
    },
  },
})
