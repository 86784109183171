import LanguageContext from '@src/context/language.context'
import {
  getPressMenu,
  getShopMenu,
  getSiteMenu,
  NavMenu,
  NavMenuMobilePlacements,
} from '@src/helpers/menu'
import {
  ConsumerPageTypes,
  PressPageTypes,
  ShopPageTypes,
  usePageType,
} from '@src/hooks/usePageType'
import { useTranslation } from '@src/hooks/useTranslation'
import { store } from '@src/store'
import { useContext, useMemo } from 'react'

export const useNavMenuMobile = (placement: NavMenuMobilePlacements): { menu: NavMenu | null } => {
  const pageType = usePageType()
  const consumerHeaderPageTypes = [...ConsumerPageTypes]
  const { locale } = useContext(LanguageContext)
  const translate = useTranslation()
  const { country } = store.getState().geolocation
  const {
    shopify: { shippingLocation },
  } = store.getState()

  const menu = useMemo(() => {
    if (placement === NavMenuMobilePlacements.MobileMenu) {
      if (consumerHeaderPageTypes.includes(pageType)) {
        return getSiteMenu(translate, locale, country, true)
      }
      if (ShopPageTypes.includes(pageType)) {
        return getShopMenu(shippingLocation, true)
      }
      if (PressPageTypes.includes(pageType)) {
        return getPressMenu(translate, true)
      }
    } else if (
      consumerHeaderPageTypes.includes(pageType) &&
      placement === NavMenuMobilePlacements.MobileMenuExtra &&
      locale === 'en-US'
    ) {
      return [
        {
          text: translate('nav-cyclematters'),
          href: '/cyclematters',
        },
        {
          text: translate('nav-store'),
          href: '/shop',
        },
      ]
    }

    return null
  }, [country, locale, pageType, shippingLocation])

  return { menu }
}
