import { HeaderLayouts } from '@src/components/header/Header.component'
import { PageTypes, PressPageTypes, ShopPageTypes } from '@src/hooks/usePageType'

export const getLayout = (pageType: PageTypes, code: string): HeaderLayouts => {
  if (PressPageTypes.includes(pageType)) {
    return HeaderLayouts.Press
  }
  if (pageType === PageTypes.ShopHome) {
    return HeaderLayouts.ShopHome
  }
  if (ShopPageTypes.includes(pageType)) {
    return HeaderLayouts.Shop
  }
  if (pageType === PageTypes.StandardHome && code) {
    return HeaderLayouts.StandardHomeDiscountBanner
  }
  if (pageType === PageTypes.StandardHome) {
    return HeaderLayouts.StandardHome
  }
  if (pageType === PageTypes.Standard && code) {
    return HeaderLayouts.StandardDiscountBanner
  }
  return HeaderLayouts.Standard
}
