export const LANGUAGES = ['en-us', 'es', 'pt-br', 'sv']

export const APP_STORE_URL =
  'https://itunes.apple.com/us/app/natural-cycles-fertility-app/id765535549?mt=8'

export const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.naturalcycles.cordova&hl='

export const SHOPIFY_US_DOMAIN = 'naturalcycles-us.myshopify.com'

export const SHOPIFY_ADMIN_DOMAIN = 'naturalcycles-us.myshopify.com'
