import BurgerIcon from '@src/components/burger-icon/BurgerIcon.component'
import Button from '@src/components/button/Button.component'
import Logo from '@src/components/logo/Logo.component'
import LanguageContext from '@src/context/language.context'
import { Breakpoints, useBreakpoint } from '@src/hooks/useBreakpoint'
import React from 'react'
import styles from './HeaderHcp.module.scss'

interface HeaderHcpProps {}

const HeaderHcp = ({}: HeaderHcpProps): React.ReactElement => {
  const isMobile = useBreakpoint().width < Breakpoints.bpMedium
  const { locale } = React.useContext(LanguageContext)

  return (
    <React.Fragment>
      <header className={styles.HeaderHcp}>
        <div className={styles.HeaderHcp__layout}>
          <div className={styles.HeaderHcp__burger}>
            <BurgerIcon color="white" />
          </div>

          <div className={styles.HeaderHcp__logo}>
            <Logo color="white" uid="header-hcp-logo" />
          </div>

          <a className={styles.HeaderHcp__research} href="/publications">
            {locale === 'sv-SE' ? 'Forskning & publikationer' : 'Research & Publications'}
          </a>
          <div className={styles.HeaderHcp__contact}>
            <Button
              size={isMobile ? 'small' : 'regular'}
              theme="whiteBrandPurple"
              href="#hcp-contact"
            >
              {locale === 'sv-SE' ? 'Kontakta oss' : 'Contact Us'}
            </Button>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default HeaderHcp
